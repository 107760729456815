<template>
  <div
    class="cell"
    :class="{
      'cell--title': contentType == 'title',
      'cell--copy': contentType == 'copy',
      'cell--image': contentType == 'image',
      'cell--video': contentType == 'video',
      'cell--empty': contentType == 'empty',
    }"
    :style="styles"
  >
    <span>{{ this.contentType }}</span>
  </div>
</template>

<script>
export default {
  props: {
    rowSpan: {
      type: Number,
      default: 0,
    },
    colSpan: {
      type: Number,
      default: 0,
    },
    contentType: {
      type: String,
      default: "",
    },
    transform: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    styles() {
      return {
        gridRow: `span ${this.rowSpan}`,
        gridColumn: `span ${this.colSpan}`,
        contentType: "",
        transform: `${this.transform}`,
      };
    },
  },
};
</script>

<style lang="scss">
.cell {
  background-color: rgba(187, 187, 187, 0.5);
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  text-transform: capitalize;

  // &--title {
  //   background-color: rgba(203, 228, 249, 0.85); //#cbe4f9;
  // }

  // &--copy {
  //   background-color: rgba(205, 245, 246, 0.85); //#cdf5f6;
  // }

  // &--image {
  //   background-color: rgba(239, 249, 218, 0.85); //#eff9da;
  // }

  // &--video {
  //   background-color: rgba(249, 235, 223, 0.85); //#f9ebdf;
  // }

  &--empty {
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    // background-color: #fff;

    span {
      display: none;
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
