<template>
  <div class="direction">
    <slot class="direction__item"></slot>
    <div class="direction__submit">
      <button class="btn" @click="changeDirection()"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    values: Array,
  },
  methods: {
    changeDirection() {
      const curIndex = this.values.indexOf(this.modelValue);

      let newIndex = curIndex + 1;
      if (newIndex >= this.values.length) newIndex = 0;

      this.$emit("update:modelValue", this.values[newIndex]);
    },
  },
};
</script>

<style lang="scss">
.direction {
  width: 110px;

  &__submit {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &::before {
      position: absolute;
      bottom: 50%;
      right: 31px;
      width: 73px;
      height: 20px;
      border-left: 1px solid var(--grey);
      border-bottom: 1px solid var(--grey);
      border-bottom-left-radius: 3px;
      content: "";
    }
  }
}
</style>
