<template>
  <div class="radio">
    <input
      class="visually-hidden"
      type="radio"
      :id="name + '-' + value"
      :name="name"
      :value="value"
      :checked="value == modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <label class="radio__label" :for="name + '-' + value">{{ title }}</label>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: String,
    value: String,
    modelValue: String,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";

.radio {
  position: relative;

  &:not(:first-child) {
    &::before {
      position: absolute;
      bottom: calc(100% + 3px);
      left: 6px;
      width: 1px;
      height: 8px;
      background-color: var(--grey);
      content: "";
    }
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  &__label {
    position: relative;
    padding-left: 22px;
    font-size: 1.4rem;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      border-radius: 50%;
      border: 1px solid var(--grey);
      transform: translateY(-50%);
      content: "";
    }

    &::before {
      left: 0;
      width: 13px;
      height: 13px;
    }

    &::after {
      left: 2px;
      width: 9px;
      height: 9px;
      @include transition;
    }

    @include when-inside("input:checked+") {
      &::after {
        background-color: var(--accent-light);
        border-color: var(--accent);
      }
    }
  }
}
</style>
