<template>
  <div class="generator container">
    <div class="generator__grid-wrapper" ref="gridWrap">
      <div class="generator__grid" :style="gridStyle" ref="grid">
        <div
          class="generator__cell"
          v-for="index in cells"
          :key="index"
          :style="cellStyle"
        ></div>
      </div>
      <div
        class="generator__grid-content"
        :style="gridContentStyle"
        ref="gridContent"
      >
        <content-cell
          v-for="(block, index) in blocks"
          :key="index"
          :row-span="block.rowSpan"
          :col-span="block.colSpan"
          :content-type="block.contentType"
          :transform="block.transform"
        ></content-cell>
      </div>
    </div>
    <div class="generator__toolbar">
      <div class="generator__group generator__group--ratio">
        <span class="generator__group-title">Ratio</span>
        <number-input v-model="cols" :reset-blocks="resetBlocks" />
        <number-input v-model="rows" :reset-blocks="resetBlocks" />
      </div>
      <div class="generator__group generator__group--content">
        <span class="generator__group-title">Content</span>
        <content-button
          name="content"
          value="title"
          title="Titles"
          v-model="contentTypes"
        ></content-button>
        <content-button
          name="content"
          value="image"
          title="Images"
          v-model="contentTypes"
        ></content-button>
        <content-button
          name="content"
          value="copy"
          title="Body Text"
          v-model="contentTypes"
        ></content-button>
        <content-button
          name="content"
          value="video"
          title="Videos"
          v-model="contentTypes"
        ></content-button>
      </div>
      <div class="generator__group generator__group--density">
        <span class="generator__group-title">Density</span>
        <density-bar
          v-model="density.rests"
          title="Rests"
          name="rests"
        ></density-bar>
        <density-bar
          v-model="density.vivace"
          title="Vivace"
          name="vivace"
        ></density-bar>
      </div>
      <div class="generator__group generator__group--direction">
        <span class="generator__group-title">Direction</span>
        <direction-tab
          v-model="direction"
          :values="['mixer', 'random', 'staves']"
        >
          <radio-button
            title="Mixer"
            name="direction"
            value="mixer"
            v-model="direction"
          ></radio-button>
          <radio-button
            title="Random"
            name="direction"
            value="random"
            v-model="direction"
          ></radio-button>
          <radio-button
            title="Staves"
            name="direction"
            value="staves"
            v-model="direction"
          ></radio-button>
        </direction-tab>
      </div>
      <div class="generator__group generator__group--generate">
        <span class="generator__group-title">Generate</span>
        <generate-tab
          :generate-layout="generateLayout"
          :generate-content="generateContent"
          :content-types="contentTypes"
          :save-image="saveImage"
          :blocks="blocks"
        ></generate-tab>
      </div>
      <p class="generator__copy">
        Designed and Developed by Forty Eight Point One for Aimi Music Ltd. Big
        shout out to Nick Jones, the creator of Figma’s Random Layout Generator.
        His plugin got us thinking ‘Hey, we could do that’ so we did, thanks
        Nick.
      </p>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import ContentCell from "./ContentCell.vue";
import NumberInput from "./NumberInput.vue";
import ContentButton from "./ContentButton.vue";
import GenerateTab from "./GenerateTab.vue";
import DirectionTab from "./DirectionTab.vue";
import RadioButton from "./RadioButton.vue";
import DensityBar from "./DensityBar.vue";

export default {
  name: "Generator",
  components: {
    ContentCell,
    NumberInput,
    ContentButton,
    GenerateTab,
    DirectionTab,
    RadioButton,
    DensityBar,
  },
  data() {
    return {
      cols: 10,
      rows: 6,
      matrix: [],
      blocks: [],
      contentTypes: [],
      density: {
        rests: 0.2,
        vivace: 0.2,
      },
      direction: "random",
      cellStyle: {
        width: "auto",
        height: "auto",
      },
      gridContentStyle: {
        gridTemplateColumns: `repeat(${this.cols}, 1fr)`,
        gridTemplateRows: `repeat(${this.rows}, 1fr)`,
      },
    };
  },
  methods: {
    saveImage() {
      // Generate screenshot and download
      html2canvas(this.$refs.gridWrap).then(function (canvas) {
        const uri = canvas.toDataURL();
        const filename = "grid.png";
        var link = document.createElement("a");

        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;

          //Firefox requires the link to be in the body
          document.body.appendChild(link);

          //simulate click
          link.click();

          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
      });
    },
    resetBlocks() {
      this.blocks = [];
    },
    generateLayout() {
      console.log("*****************generateLayout**************");
      console.log("Generating Layout...");

      this.resetBlocks();
      this.initialiseMatrix();
      this.generateBlocks();
    },
    generateContent() {
      console.log("generateContent");
      let contentIndex;
      for (let i = 0; i < this.blocks.length; i++) {
        contentIndex = Math.floor(Math.random() * this.contentTypes.length);
        if (this.blocks[i].contentType !== "empty") {
          this.blocks[i].contentType = this.contentTypes[contentIndex];
        }
      }
    },
    getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    initialiseMatrix() {
      console.log("Initialising matrix...");
      this.matrix = new Array(this.rows)
        .fill()
        .map(() => new Array(this.cols).fill(0));
    },
    fillMatrix(rowStart, rowEnd, colStart, colEnd) {
      console.log("Filling matrix...");
      for (let i = rowStart; i < rowEnd; i++) {
        for (let j = colStart; j < colEnd; j++) {
          this.matrix[i][j] = 1;
        }
      }
    },
    findFirstEmptyCell() {
      console.log("Finding first empty cell...");
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.cols; j++) {
          if (this.matrix[i][j] == 0) return [i, j];
        }
      }
      return [];
    },
    findLastEmptyCol(firstEmptyCell) {
      let j = firstEmptyCell[1]; // col number of first empty cell
      let iCord = firstEmptyCell[0];
      let lastEmptyCol;
      while (j < this.cols) {
        if (this.matrix[iCord][j] === 0) {
          lastEmptyCol = j;
          j++;
        } else {
          break;
        }
      }
      return lastEmptyCol;
    },
    generateBlock(firstEmptyCell) {
      const lastEmptyCol = this.findLastEmptyCol(firstEmptyCell);
      console.log("first empty cell", firstEmptyCell);
      console.log("last empty col", lastEmptyCol);
      const lastAvailableRow = this.rows - 1;
      let rowRange = lastAvailableRow - firstEmptyCell[0] + 1;
      let colRange = lastEmptyCol - firstEmptyCell[1] + 1;

      console.log("lastAvailableRow", lastAvailableRow);
      console.log("rowRange", rowRange);
      console.log("colRange", colRange);

      if (this.blocks.length < 5) {
        rowRange = Math.floor(this.getRandomNumber(1, 0.7 * rowRange));
        colRange = Math.floor(this.getRandomNumber(1, 0.7 * colRange));
        // rowRange = Math.floor(0.7 * rowRange);
        // colRange = Math.floor(0.7 * colRange);
        console.log("First blocks");
        console.log("rowRange", rowRange);
        console.log("colRange", colRange);
      }

      const vivaceCo = 1.2 - this.density.vivace;
      // const vivaceCo = this.getRandomNumber(1.2 - this.density.vivace, 1);
      console.log("vivaceCo", vivaceCo);

      let colSpan;
      let rowSpan;
      if (this.direction === "staves") {
        console.log("Staves - horizontal");
        // Compute colSpan first, then restrict max allowed rowSpan according to colSpan computed
        colSpan =
          Math.floor(
            this.getRandomNumber(
              colRange / (this.density.vivace * 10),
              vivaceCo * colRange
            )
          ) + 1;

        // randomise the maximum so that it's not that biased towards creating small blocks
        let dice = Math.random();
        console.log("dice", dice);
        const maxRowSpan =
          dice < 0.9
            ? Math.min(vivaceCo * rowRange, colSpan - 1)
            : vivaceCo * rowRange;
        // const maxRowSpan = Math.min(vivaceCo * rowRange, colSpan - 1);
        rowSpan =
          Math.floor(
            this.getRandomNumber(
              rowRange / (this.density.vivace * 10),
              maxRowSpan
            )
          ) + 1;
      } else if (this.direction === "mixer") {
        console.log("Mixer - vertical");
        // Compute rowSpan first, then restrict max allowed colSpan according to rowSpan computed
        rowSpan =
          Math.floor(
            this.getRandomNumber(
              rowRange / (this.density.vivace * 10),
              vivaceCo * rowRange
            )
          ) + 1;

        if (firstEmptyCell[0] !== lastAvailableRow) {
          // randomise the maximum so that it's not that biased towards creating small blocks
          let dice = Math.random();
          console.log("dice", dice);
          const maxColSpan =
            dice < 0.9
              ? Math.min(vivaceCo * colRange, rowSpan - 1)
              : vivaceCo * colRange;
          // const maxColSpan = Math.min(vivaceCo * colRange, rowSpan - 1);
          colSpan =
            Math.floor(
              this.getRandomNumber(
                colRange / (this.density.vivace * 10),
                maxColSpan
              )
            ) + 1;
        } else {
          console.log("Last Row cells");
          colSpan =
            Math.floor(
              this.getRandomNumber(
                colRange / (this.density.vivace * 10),
                vivaceCo * colRange
              )
            ) + 1;
        }
      } else {
        console.log("Random");
        // this is as it was before the direction rules
        colSpan =
          Math.floor(
            this.getRandomNumber(
              colRange / (this.density.vivace * 10),
              vivaceCo * colRange
            )
          ) + 1;
        rowSpan =
          Math.floor(
            this.getRandomNumber(
              rowRange / (this.density.vivace * 10),
              vivaceCo * rowRange
            )
          ) + 1;
      }

      console.log("rowSpan ", rowSpan);
      console.log("colSpan ", colSpan);

      // fillMatrix(rowStart, rowEnd, colStart, colEnd)
      this.fillMatrix(
        firstEmptyCell[0],
        firstEmptyCell[0] + rowSpan,
        firstEmptyCell[1],
        firstEmptyCell[1] + colSpan
      );

      this.blocks.push({
        colSpan: colSpan,
        rowSpan: rowSpan,
        contentType: "",
        transform: "",
      });
    },
    generateBlocks() {
      let firstEmptyCell = this.findFirstEmptyCell();
      console.log("first empty cell", firstEmptyCell);

      while (firstEmptyCell.length) {
        this.generateBlock(firstEmptyCell);
        console.log(this.matrix);
        firstEmptyCell = this.findFirstEmptyCell();
      }
      this.applyRandomTransform();
      this.allocateEmptySpace();
    },
    applyRandomTransform() {
      console.log("applyRandomTransform");
      const randNumber = Math.random();
      const generatorGrid = this.$refs.gridWrap;

      // console.log(generatorCells);
      console.log(randNumber);
      let randomTransform;
      if (randNumber < 0.25) {
        // randomTransform = "scaleX(-1)";
        randomTransform = "rotateX(180deg)";
      } else if (randNumber < 0.5) {
        // randomTransform = "scaleX(-1) rotate(180deg)";
        randomTransform = "rotateX(180deg) rotateY(180deg)";
      } else if (randNumber < 0.75) {
        // randomTransform = "scaleY(-1)";
        randomTransform = "rotateY(180deg)";
      } else {
        console.log("no transform");
        randomTransform = "none";
      }
      generatorGrid.style.transform = randomTransform;

      for (let i in this.blocks) {
        this.blocks[i].transform = randomTransform;
      }
    },
    allocateEmptySpace() {
      //rests is the percentage of empty space, will come from UI
      console.log("**********allocateEmptySpace*****************");
      const emptyBlocksNum = Math.round(
        this.density.rests * this.blocks.length
      );
      console.log("allBlocksNum", this.blocks.length);
      console.log("emptyBlocksNum", emptyBlocksNum);
      // Shuffle array
      const indexArray = Array.from(new Array(this.blocks.length).keys());
      const shuffledindexArray = indexArray.sort(() => 0.5 - Math.random());
      // Get sub-array of first n elements after shuffled
      let selected = shuffledindexArray.slice(0, emptyBlocksNum);
      console.log(this.blocks[selected[0]]);
      for (let i = 0; i < selected.length; i++) {
        this.blocks[selected[i]].contentType = "empty";
      }
    },
    resize() {
      // get size of the wrapper
      const elSize = {
        width: this.$refs.gridWrap.offsetWidth,
        height: this.$refs.gridWrap.offsetHeight,
      };
      // get grid gap val
      const styles = window.getComputedStyle(this.$refs.gridContent);
      const gap = styles.getPropertyValue("grid-gap");
      const gapVal = parseInt(gap.split(" ")[0], 10);

      // calculate max width for the cell based on wrapper width
      const equalWidth = (elSize.width - (this.cols - 1) * gapVal) / this.cols;

      // calculate max height for the cell based on wrapper height
      const equalHeight =
        (elSize.height - (this.rows - 1) * gapVal) / this.rows;
      let size = "auto";

      // check if rows will fit container if height will be equal to width
      if (equalWidth * this.rows + gapVal * (this.rows - 1) < elSize.height) {
        size = equalWidth + "px";

        // do the opposite
      } else {
        size = equalHeight + "px";
      }

      // cell styles
      this.cellStyle = {
        width: size,
        height: size,
      };

      // content grid styles
      this.gridContentStyle = {
        gridTemplateColumns: `repeat(${this.cols}, ${size})`,
        gridTemplateRows: `repeat(${this.rows}, ${size})`,
      };
    },
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  computed: {
    cells() {
      return this.cols * this.rows;
    },
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.cols}, 1fr)`,
        gridTemplateRows: `repeat(${this.rows}, 1fr)`,
      };
    },
  },
  watch: {
    cells() {
      this.resize();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";
$accent: #f17878;

.generator {
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 100vh;

  &__grid-wrapper {
    position: relative;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 30vh;

    @include respond-to(sm) {
      height: calc(100vh - 120px - 220px - 50px);
    }
  }

  &__grid,
  &__grid-content {
    margin-top: auto;
    margin-bottom: auto;
    max-height: 100%;
    display: grid;
    flex-shrink: 1;
    grid-auto-flow: row dense;
    grid-gap: 4px;

    @include respond-to(sm) {
      grid-gap: 10px;
    }
  }

  &__grid-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $accent;
      content: "";
    }
  }

  &__cell {
    position: relative;
    width: 100%;
    background-color: rgba($accent, 0.1);
    box-sizing: border-box;

    &::before {
      padding-top: 100%;
      display: block;
      content: "";
    }
  }

  &__toolbar {
    position: relative;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(sm) {
      flex-direction: row;
    }
  }

  &__group {
    position: relative;
    padding: 24px 11px 13px 11px;
    border-radius: 4px;
    border: 1px solid #bbbbbb;

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }

    @include respond-to(sm) {
      &:not(:last-of-type) {
        margin-bottom: 0;
        margin-right: 4px;
      }
    }
  }

  &__group-title {
    position: absolute;
    top: 0;
    left: 10px;
    background-color: #fff;
    transform: translate(0, -50%);
    padding: 5px;
  }

  &__copy {
    margin-top: 15px;
    font-size: 1.1rem;
    width: 100%;
    max-width: 565px;
    text-align: center;

    @include respond-to(sm) {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-top: 0;
      transform: translateX(-50%);
    }
  }
}
</style>
