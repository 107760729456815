<template>
  <LayoutGenerator />
</template>

<script>
import LayoutGenerator from "./components/LayoutGenerator.vue";

export default {
  name: "App",
  components: {
    LayoutGenerator,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
