<template>
  <label :for="name + '-' + value" class="checkbox">
    <span class="checkbox__title">
      {{ title }}
    </span>
    <input
      class="visually-hidden"
      type="checkbox"
      :id="name + '-' + value"
      :name="name"
      :value="value"
      v-model="model"
    />
    <span class="checkbox__thumb btn"></span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
    },
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";

.checkbox {
  display: flex;
  justify-content: space-between;
  width: 110px;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    flex-grow: 1;

    &::after {
      margin-left: 4px;
      margin-right: 2px;
      flex-grow: 1;
      height: 1px;
      background-color: var(--grey);
      content: "";
    }
  }

  &__thumb {
    border-radius: 2px;
  }
}
</style>
