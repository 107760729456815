<template>
  <div class="generate">
    <button class="generate__btn btn btn--accent" @click="generateLayout()">
      Layout
    </button>
    <button
      class="generate__btn btn btn--accent"
      @click="generateContent()"
      :disabled="!contentTypes.length"
    >
      Content
    </button>

    <div class="generate__save">
      <span>Save</span>
      <button
        class="btn"
        :disabled="!blocks.length"
        @click="saveImage"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    generateLayout: Function,
    generateContent: Function,
    contentTypes: Array,
    saveImage: Function,
    blocks: Array,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";

.generate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__btn.btn {
    width: 104px;
    height: auto;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__save {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    width: 100%;

    .btn {
      position: relative;

      &::before {
        position: absolute;
        bottom: 50%;
        right: calc(100% + 2px);
        width: 30px;
        height: 1px;
        // border-left: 1px solid var(--grey);
        border-bottom: 1px solid var(--grey);
        border-bottom-left-radius: 3px;
        content: "";
      }
    }
  }
}
</style>
