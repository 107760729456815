<template>
  <div class="range">
    <div class="range__header">
      <span>{{ title }}</span>
      <digi-num :number="modelValue * 100" :border="true"></digi-num>
    </div>
    <div class="range__slider">
      <vue-slider
        v-model="value"
        :interval="0.1"
        :min="0.2"
        :max="0.8"
        tooltip="none"
        :railStyle="{
          backgroundColor: '#ECECEC',
          border: '1px solid #BBBBBB',
        }"
        :processStyle="{ backgroundColor: 'transparent' }"
      >
        <template v-slot:dot>
          <div class="range__dot"></div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import DigiNum from "./DigiNum.vue";
import VueSlider from "vue-slider-component";

export default {
  components: { DigiNum, VueSlider },
  props: {
    title: String,
    name: String,
    modelValue: Number,
  },
  data() {
    return {
      something: 0,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss">
/* import theme style */
@import "~vue-slider-component/lib/theme/default.scss";

.range {
  width: 170px;
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &__header {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      order: -2;
    }

    &::after {
      margin-left: 4px;
      height: 1px;
      background-color: var(--grey);
      order: -1;
      flex-grow: 1;
      content: "";
    }
  }

  &__slider {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 29px);
  }

  &__dot {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 29px;
    height: 19px;
    border-radius: 2px;
    border: 1px solid var(--grey);
    background-color: var(--light-grey);
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
</style>
