<template>
  <div
    class="digi"
    :class="{
      'digi--border': border,
    }"
  >
    <span class="digi__num" :class="firstNum"> </span>
    <span class="digi__num" :class="secondNum"> </span>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    border: Boolean,
  },
  computed: {
    firstNum() {
      if (this.number > 9) {
        const str = this.number.toString();
        return `is-${str.charAt(0)}`;
      } else {
        return "";
      }
    },
    secondNum() {
      const str = this.number.toString();

      if (this.number > 9) {
        return `is-${str.charAt(1)}`;
      } else {
        return `is-${str.charAt(0)}`;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";

.digi {
  display: flex;

  &--border {
    padding: 3px;
  }

  &__num {
    position: relative;
    width: 18px;
    height: 31px;
    display: block;
    background: url("../assets/img/base.svg") no-repeat center center;
    background-size: 100%;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      content: "";
    }

    @include when-inside(".digi--border") {
      &:not(:last-child) {
        margin-right: 8px;
      }

      &::before {
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 1px solid var(--grey);
        border-radius: 2px;
        content: "";
      }
    }

    &.is-0::after {
      background-image: url("../assets/img/0.svg");
    }

    &.is-1::after {
      background-image: url("../assets/img/1.svg");
    }

    &.is-2::after {
      background-image: url("../assets/img/2.svg");
    }

    &.is-3::after {
      background-image: url("../assets/img/3.svg");
    }

    &.is-4::after {
      background-image: url("../assets/img/4.svg");
    }

    &.is-5::after {
      background-image: url("../assets/img/5.svg");
    }

    &.is-6::after {
      background-image: url("../assets/img/6.svg");
    }

    &.is-7::after {
      background-image: url("../assets/img/7.svg");
    }

    &.is-8::after {
      background-image: url("../assets/img/8.svg");
    }

    &.is-9::after {
      background-image: url("../assets/img/9.svg");
    }
  }
}
</style>
