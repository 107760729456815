<template>
  <div class="number">
    <digi-num class="number__digi" :number="modelValue"></digi-num>
    <input class="visually-hidden" type="number" :value="modelValue" readonly />
    <div class="number__btns">
      <button @click="increment()" :disabled="modelValue >= 20">+</button>
      <button @click="decrement()" :disabled="modelValue <= 5">-</button>
    </div>
  </div>
</template>

<script>
import DigiNum from "./DigiNum.vue";

export default {
  components: {
    DigiNum,
  },
  props: {
    modelValue: Number,
    resetBlocks: Function,
  },
  methods: {
    increment() {
      this.resetBlocks();
      let newVal = this.modelValue + 1;
      this.$emit("update:modelValue", newVal);
    },
    decrement() {
      this.resetBlocks();
      let newVal = this.modelValue - 1;
      if (newVal < 5) newVal = 5;

      this.$emit("update:modelValue", newVal);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";

.number {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--grey);
  border-radius: 4px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &__digi {
    padding: 8px;
    min-width: 80px;
  }

  &__btns {
    border-left: 1px solid var(--grey);

    button {
      width: 28px;
      height: 50%;
      font-family: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      line-height: 1;
      color: #bebdbd;
      user-select: none;
      background-color: var(--light-grey);
      @include transition;

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey);
      }

      @include hover {
        color: darken(#bebdbd, 15%);
        background-color: darken(#f5f5f5, 15%);
      }
    }
  }
}
</style>
